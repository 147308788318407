import { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Loader from "./components/Loader";
import { Admin, Resource, fetchUtils } from "react-admin";
import restProvider from "ra-data-simple-rest";
import BlogList from "./components/BlogList";
import BlogCreate from "./components/BlogCreate";
import BlogEdit from "./components/BlogEdit";
import PortfolioList from "./components/ProjectsList";
import ProjectCreate from "./components/ProjectCreate";
import ProjectEdit from "./components/ProjectEdit";
import { CVList, CVEdit } from "./components/CV";
import {
  CertificatesCreate,
  CertificatesEdit,
  CertificatesList,
} from "./components/CertificateAdmin";
import AuthenticationProvider from "./components/AuthProvider";
import { SkeletonTheme } from "react-loading-skeleton";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Home = lazy(() => import("./components/Home"));
const ContactPage = lazy(() => import("./components/ContactPage"));
const BlogPage = lazy(() => import("./components/BlogPage"));
const BlogDetailsPage = lazy(() => import("./components/BlogDetailsPage"));
const NotFoundPage = lazy(() => import("./noPage/NoPage"));

const App = () => {
  // Light mode
  const [isLightMode] = useState(() => {
    // Retrieve mode from local storage or default to false (dark mode)
    const savedMode = localStorage.getItem("isLightMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });
  // const [portfolioHeight, setPortfolioHeight] = useState(0);

  useEffect(() => {
    // Apply the light mode class to the body on initial load
    if (isLightMode) {
      document.body.classList.add("light-mode");
    }
  }, [isLightMode]);

  // Scroll to top when the route changes
  const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, [location]);

    return null;
  };

  // Sticky nav
  window.onscroll = () => {
    // Active link on scroll
    const sections = document.querySelectorAll("section");
    const navLinks = document.querySelectorAll("nav ul li a");

    window.addEventListener("scroll", () => {
      sections.forEach((sec) => {
        let top = window.scrollY;
        let offset = sec.offsetTop - 150;
        let height = sec.offsetHeight;
        let id = sec.getAttribute("id");

        if (top >= offset && top < offset + height) {
          navLinks.forEach((link) => {
            link.classList.remove("active");
            document
              .querySelector("header nav a[href*=" + id + "]")
              .classList.add("active");
          });
        }
      });
    });
  };

  // Scroll to section on page load
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const sectionId = hash.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView();
      }
    }
  }, []);
  return (
    <SkeletonTheme
      baseColor={isLightMode ? "#b5b3b3" : "#0F101B"}
      // highlightColor="#202238"
      highlightColor="#029999"
    >
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route
              path="/contact"
              element={<ContactPage isLightMode={isLightMode} />}
            />
            <Route
              path="/blogs"
              element={<BlogPage isLightMode={isLightMode} />}
            />
            <Route path="/blogs/:id" element={<BlogDetailsPage />} />
            <Route path="/admin/*" element={<AdminRoutes />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Router>
    </SkeletonTheme>
  );
};

const httpClient = async (url, options = {}) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/admin/login";
      throw new Error("Redirecting to login");
    }

    const headers = new Headers(options.headers || {});
    headers.set("Authorization", `Bearer ${token}`);

    const response = await fetchUtils.fetchJson(url, {
      ...options,
      headers,
    });

    // Handle token expiration (status 401)
    if (response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }

    return response;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

const AdminRoutes = () => {
  return (
    <Admin
      authProvider={AuthenticationProvider}
      basename="/admin"
      dataProvider={restProvider(BASE_URL, httpClient, "X-Total-Count")}
    >
      <Resource
        name="enblogs"
        list={BlogList}
        create={BlogCreate}
        edit={BlogEdit}
      />
      <Resource
        name="arblogs"
        list={BlogList}
        create={BlogCreate}
        edit={BlogEdit}
      />
      <Resource
        name="projects"
        list={PortfolioList}
        create={ProjectCreate}
        edit={ProjectEdit}
      />
      <Resource name="cv" list={CVList} edit={CVEdit} />
      <Resource
        name="certificates"
        list={CertificatesList}
        create={CertificatesCreate}
        edit={CertificatesEdit}
      />
    </Admin>
  );
};

export default App;
